
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@font-face {
  font-family: 'Edwardian Script ITC';
  src: url('./edwardianscriptitc.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.carousel {
  background-color: red;
  /* Remove border radius */
  /* Add other styles here */
}

body {
  background-color: #F8E5E5;

}

.bg-burgundy {
  background-color: #A45D73;
}
.text-burgundy {
  color: #A45D73;
}

.bg-slate {
  background-color: #F4C2C2;
}

.policy ul {
  list-style-type: circle;
  /* Change bullet style */
  padding-left: 20px;
  /* Indent the list */
}

.policy li {
  margin-bottom: 5px;
  /* Add space between items */
}
.policy p {
color: black  /* Add space between items */
}
.dm-serif-text-regular {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.dm-serif-text-regular-italic {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: italic;
}
p, h1,h2, h3, span, body {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.libre-baskerville-regular {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
}

.libre-baskerville-bold {
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
  font-style: normal;
}

.libre-baskerville-regular-italic {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: italic;
}

.libre-baskerville-regular-italic-bold {
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
  font-style: italic;
}
.swift-finds {
  font-family: "Edwardian Script ITC", serif;
  color: #EFE5D7;
}

.text-pallete-2 {
  color: #EFE5D7;
}

.service-logo {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.service-logo:hover {
  transform: scale(1.1);
  /* Enlarges the element on hover */
}

.category-div,
.testing {
  border-bottom: 2px solid grey;
  cursor: pointer;
  width: 90%;
  transition: transform 0.3s ease, border-bottom-color 0.3s ease;
  /* Smooth transition for both scale and border */

}


.category-div:hover {
  transform: scale(1.1);
  /* Zoom effect */
  border-bottom-color: black;
  /* Change border-bottom to black on hover */
}

.icon-rotate {
  font-size: 48px;
  /* Adjust the size of the icon */
  transition: transform 0.3s ease-in-out;
  /* Smooth transition */
}

.category-div:hover .icon-rotate {
  transform: rotate(45deg); /* Twist the icon by 180 degrees */
}

@media (min-width: 1024px) {
  .swift-finds {
    font-size: 200px;
  }
}

@media (max-width: 1023px) {
  .swift-finds {
    font-size: 120px;
  }
}

@media (max-width: 640px) {
  .swift-finds {
    font-size: 80px;
  }
}
.scrollable-container {
  height: 300px;
  /* Adjust height as needed */
  overflow: auto;
  /* Adds scrollbars if content overflows */
}

/* Custom Scrollbar Styles */
.scrollable-container::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Roundness of the scrollbar thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}